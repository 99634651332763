import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import useLocalStorage from '../hooks/useLocalStorage';
import { UTM_PARAMS, UTM_STORAGE_KEY, UTM_EXPIRATION_DAYS } from '../constants/utm';

// ----------------------------------------------------------------------

const isUtmExpired = (expirationDate) => {
  if (!expirationDate) return true;
  return new Date() > new Date(expirationDate);
};

const getExpirationDate = () => {
  const date = new Date();
  date.setDate(date.getDate() + UTM_EXPIRATION_DAYS);
  return date.getTime();
};

export default function UtmGuard({ children }) {
  const [searchParams] = useSearchParams();
  const [storedUtmParams, setUtmParams] = useLocalStorage(UTM_STORAGE_KEY, null);

  useEffect(() => {
    if (storedUtmParams && isUtmExpired(storedUtmParams.expirationDate)) {
      setUtmParams(null);
    }

    const utmParameters = {};
    let hasUtmParams = false;

    UTM_PARAMS.forEach((param) => {
      const value = searchParams.get(param);
      if (value) {
        utmParameters[param] = value;
        hasUtmParams = true;
      }
    });

    if (hasUtmParams) {
      utmParameters.expirationDate = getExpirationDate();
      setUtmParams(utmParameters);
    }
  }, [searchParams]);

  return <>{children}</>;
}

UtmGuard.propTypes = {
  children: PropTypes.node,
};
